/**
 * Methods for creating and manipulating a row of data in the OMC table
 *
 * @module
 */

/**
 * Create a set of rows for all the types requested
 * @param omcType {string | string[]} - The omc types to be included in the table
 * @param omcDict {object}
 * @param skosDict {object}
 * @returns {(*&{rdfClass: null, skosDefinition: null})[]}
 */

export function createOmcJsonTable(omcType, omcDict, skosDict) {
    const typeId = omcDict.getType(omcType); // All nodes of the specified type

    // Create the row with the SkOS concept if there is one
    return typeId.map((omcId) => {
        const omcEnt = omcDict.getEntity(omcId)
        return {
            ...omcEnt,
            skosDefinition: skosDict.getEntity(omcEnt.skosDefinition),
        }
    })
}


export function createControlledVocab(omcDict) {
    const properties = ((omcId) => {
        const childProperties = (omcDict.getRelated(omcId, 'hasProperty')) // Properties, that are not Entities
            .map((cId) => omcDict.getEntity(cId))
            .filter((t) => t.type === 'omc:Property');
        return childProperties.flatMap((cEnt) => {
            const cv = omcDict.getRelated(cEnt.id, 'hasControlledValue');
            return (cv.length)
                ? cEnt.id
                : properties(cEnt.id);
        })
    });

    const subValues = ((omcId, label, circular = []) => {
        if (circular.includes(omcId)) return [];
        const sv = omcDict.getRelated(omcId, 'hasSubValue');
        return sv.map((sId) => {
            const sEnt = omcDict.getEntity(sId);
            const svLabel = [label, sEnt.label].join('.');
            return {
                label: svLabel,
                id: sId,
                subValue: subValues(sId, svLabel, [...circular, ...sv]),
            }
        })
    });

    const controlledValues = ((omcId) => {
        const cv = omcDict.getRelated(omcId, 'hasControlledValue');
        return cv.map((cId) => {
            const cvEnt = omcDict.getEntity(cId);
            return {
                label: cvEnt.label,
                id: cId,
                subValue: subValues(cId, cvEnt.label),
            }
        })
    });

    const omcEntityIds = omcDict.getType('omc:Entity');
    const omcEntities = omcEntityIds.map((id) => omcDict.getEntity(id));
    const entControlledValues = omcEntities.reduce((obj, ent) => {
        const cProps = (properties(ent.id))
            .map((pId) => (omcDict.getEntity(pId)));
        return cProps.length
            ? { ...obj, ...{ [ent.id]: cProps } }
            : obj;
    }, {})

    const list = {}
    for (const entId in entControlledValues) {
        list[entId] = entControlledValues[entId].map((pEnt) => ({
            label: pEnt.label,
            id: pEnt.id,
            subValue: controlledValues(pEnt.id)
        }));
    }
    return list
}

export function functionalPropertyList(omcDict) {
    const controlledValues = createControlledVocab(omcDict); // Extract the controlled values
    const result = {};

    // Flatted array of subValues
    const subValues = ((sv) => (
        sv.flatMap((sv) => ([
            sv.label,
            ...subValues(sv.subValue),
        ]))));

    // Object with Entity label, property label, and subValues
    for (const entId in controlledValues) {
        const ent = omcDict.getEntity(entId);
        result[ent.label] = controlledValues[entId].map((p) => {
            const t = subValues(p.subValue);
            return { [p.label]: t }
        });
    }
    return result;
}

export function controlledValueList(omcDict) {
    const controlledValues = createControlledVocab(omcDict); // Extract the controlled values
    const subValues = ((sv, id = []) => (
        sv.flatMap((sv) => ([
            [ `${[...id, sv.id].join('-')}`, sv.label ],
            ...subValues(sv.subValue, [`${[...id, sv.id].join('-')}`]),
        ]))));

    const result = Object.keys(controlledValues).flatMap((entId) => {
        return controlledValues[entId].flatMap((p) => subValues(p.subValue));
    })
    return Object.fromEntries(result);
}