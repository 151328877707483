import React from 'react';
import { PropTypes } from 'prop-types';
import {
    Box,
    Heading,
    Text,
    Stack,
    useStyleConfig,
} from '@chakra-ui/react';

import { nodeParams } from './omcJsonDefaults.mjs';
import { controlledValueList } from '../../../services/vocabulary/omcTable.mjs';
import { useSkosDictionary } from '../../../hooks/SkosDictionaryContext';
import { useOmcDictionary } from '../../../hooks/OmcDictionaryContext';

// Retrieve the controlled value (with dot notation) based on the d3id
const grabCv = ((d3id, omcDictionary) => {
    const checkId = d3id.replaceAll('_', ':');
    const controlledValues = controlledValueList(omcDictionary);
    const candidates = Object.keys(controlledValues).filter((cvId) => checkId.includes(cvId))
        .sort((a, b) => b.length - a.length);
    return controlledValues[candidates[0]] || 'N.A';
});

function OmcJsonSideBar({
    sideBarContent = null,
}) {
    if (!sideBarContent || !sideBarContent.id) return null; // Must be a valid identifier
    const { id, d3id } = sideBarContent;

    const sideBarBodyStyle = useStyleConfig('SideBarBody');
    const sideBarHeaderStyle = useStyleConfig('SideBarHeader');
    const skosDictionary = useSkosDictionary();
    const omcDictionary = useOmcDictionary();

    const sideBar = omcDictionary.getEntity(id); // Get the up to date data for the entity
    if (!sideBar) return null; // Protects against deletion while shown

    // Set up the text to be displayed with checks for applicability
    const rdfClass = sideBar.value ? sideBar.value.rdfClass : 'N/A';
    const label = sideBar.label ? sideBar.label : 'N/A';
    const skosDefinition = sideBar.skosDefinition ? skosDictionary.getEntity(sideBar.skosDefinition) : null;
    const value = (sideBar.type === 'omc:ControlledValue') ? grabCv(d3id, omcDictionary) : 'N/A';

    const headerParams = nodeParams[sideBar.type]; // What type of node is this

    return (
        <Box w="25%" overflow="auto">
            <Box __css={sideBarHeaderStyle} backgroundColor={headerParams.style.color} p={2}>
                <Heading size="sm" ml={1} mr={2}>{headerParams.label}</Heading>
            </Box>
            <Box __css={sideBarBodyStyle}>
                <Stack>
                    <Box>
                        <Text fontWeight="bold">OMC Label</Text>
                        <Text>{label}</Text>
                    </Box>
                    <Box>
                        <Text fontWeight="bold">OMC Id</Text>
                        <Text>{id}</Text>
                    </Box>
                    <Box>
                        <Text fontWeight="bold">Controlled Value</Text>
                        <Text>{value}</Text>
                    </Box>
                    <Box>
                        <Text fontWeight="bold">RDF Class</Text>
                        <Text>{(rdfClass ? rdfClass.label : 'N/A')}</Text>
                    </Box>
                    <Box>
                        <Text fontWeight="bold">SKOS Concept</Text>
                        <Text>
                            {((skosDefinition) ? skosDefinition.prefLabel.value : 'N/A')}
                        </Text>
                    </Box>
                    <Box>
                        <Text fontWeight="bold">SKOS Definition</Text>
                        <Text>
                            {((skosDefinition) ? skosDefinition.definition : 'N/A')}
                        </Text>
                    </Box>
                </Stack>
            </Box>
        </Box>
    );
}

OmcJsonSideBar.propTypes = {
    sideBarContent: PropTypes.shape({ // The id of a dictionary entity to be displayed
        id: PropTypes.string,
        d3id: PropTypes.string,
    }),
};

export default OmcJsonSideBar;
