import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { select } from 'd3-selection';
import { tree } from 'd3-hierarchy';
import { drag } from 'd3-drag';

import { useOmcDictionary, useOmcJsonRoot } from '../../../hooks/OmcDictionaryContext';
import useTraceUpdate from '../../../hooks/useTraceUpdate';

import {
    nodeParams,
    fontHeight,
    nodeSpaceVertical,
    minSpaceHorizontal,
    statusColor,
    canDrop,
} from './omcJsonDefaults.mjs';

import {
    createD3Node,
    omcJsonParams,
    diagonal,
    exitOffsetLink,
    linkExitOffsetLink,
    entryOffsetLink,
    dragNodePosition,
    treeHeight,
    dragStarted,
    dragCoords,
    dragNodeTransform,
    dragged,
    hasCollided,
    omcD3id,
} from '../../../services/vocabulary/d3Utilities.mjs';

let altKey = false;

function OmcJsonTreeDiagram(props) {
    const {
        updateSideBar = () => {}, // Function to update sidebar
        updateDragNode = () => {}, // Call to change the draggable node
        updateChart = () => {}, // Call to update the display
        displayMessage = () => {}, // Display a message to the user such as a warning
        displayContextMenu = () => {}, // Display a context menu for the user
        triggerRender = null, // Forces a re-render of the tree after table has updated
        treeId = 'omcJsonTree', // Unique Id for the tree container in DOM
        dragNodeId = { nodeId: null, location: null }, // Id of the current draggable node
        containerDimensions = { width: 0, height: 0 }, // The dimensions of the container for the tree
        zoomPosition = { k: 1, x: 0, y: 0 }, // The current zoom position of the tree
    } = props;

    console.log('Render: OmcJsonTreeDiagram');
    useTraceUpdate(props);

    const root = useOmcJsonRoot();
    const [currentDragNode, setCurrentDragNode] = useState(null);
    const [gNode, setGNode] = useState(null);
    const [gLink, setGLink] = useState(null);
    const [svg, setSVG] = useState(null);

    const nodeRender = omcJsonParams(nodeParams);
    const omcDictionary = useOmcDictionary();
    const { width: containerWidth, height: containerHeight } = containerDimensions;

    const handleKeyDown = useCallback((e) => { altKey = e.altKey; }, []);
    const handleKeyUp = useCallback((e) => { altKey = e.altKey; }, []);

    // Initialize the selections for the tree diagram and set the event listener for altKey to slow transitions
    useEffect(() => {
        // Select the main container using the tree's id
        setSVG(select(`#${treeId}-container`)); // The g that zoom is on.
        setGNode(
            select(`#${treeId}-container`) // Select the g container for the graphs nodes
                .select('g.treeNode'),
        );
        // Select the g container for the graphs links
        setGLink(
            select(`#${treeId}-container`)
                .select('g.treeLink'),
        );
        console.log('gNode has been set');

        // Holding altKey slows down the transition
        document.addEventListener('keydown', (e) => { altKey = e.altKey; });
        document.addEventListener('keyup', (e) => { altKey = e.altKey; });

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, [treeId, handleKeyDown, handleKeyUp]);

    // If the drag node changes, calculate it's starting position, or set to null if removed
    useEffect(() => {
        const { nodeId, location } = dragNodeId || {};
        console.log(`Drag Node has changed: ${nodeId}`);

        if (!nodeId) {
            setCurrentDragNode(null); // Remove the draggable node if there was one
            return;
        }

        // Position the draggable node at the specified location, or the center of the screen
        const { xPos: x, yPos: y } = dragNodePosition(zoomPosition, containerWidth, containerHeight);
        const d3Node = location
            ? createD3Node(nodeId, omcDictionary, omcD3id, { ...location, draggable: true })
            : createD3Node(nodeId, omcDictionary, omcD3id, {
                y, y0: y, x, x0: x, draggable: true,
            });
        setCurrentDragNode(d3Node);
    }, [dragNodeId]);

    async function contextAction(action, node) {
        console.log(`Executing ${action} on node ${node.data.id}`);

        if (action === 'cancelDrag') {
            const [dragX, dragY] = dragCoords(node);
            updateDragNode(null, { y: dragY, x: dragX });
            return;
        }

        // Pass the action to the reducer for processing
        const updateResponse = omcDictionary.changeOMCRequest(action, {
            parent: node.parent.data,
            node: node.data,
        });

        await updateChart(updateResponse); // Update the database, handle errors and warnings

        if (action === 'moveProperty' || action === 'moveControlledValue' || action === 'copyProperty') {
            const dragId = node.data.id;
            updateDragNode(dragId, { x: node.x, x0: node.x, y: node.y, y0: node.y }); // Clear the dragged node
        }
        updateSideBar(); // Update the sidebar, if it is not showing a removed node it will be cleared
    }

    async function dragEnded(e, d) {
        if (!d.draggable) return;

        // Was the dragged node dropped onto another node
        const [dragX, dragY] = dragCoords(d);
        const { nodes } = d;
        const shape = nodeRender.shape(d);

        const { type } = d.data; // Only nodes of the correct type can be dropped on
        const candidateType = canDrop[type];
        const candidateNodes = nodes.filter((n) => candidateType.includes(n.data.type));

        const collidedNode = candidateNodes.find((n) => (
            hasCollided(n.x - dragX, n.y - dragY, shape) // Adjust for the dragged position and shape
        ));

        if (collidedNode) { // The draggable node has not been dropped over another node
            const actionVerb = (d.data.type === 'omc:Property' || d.data.type === 'omc:Entity' || d.data.type === 'omc:Container')
                ? 'insertProperty' : 'insertControlledValue';
            const updateRequest = omcDictionary.changeOMCRequest(actionVerb, {
                parent: { schemaId: collidedNode.d3id, ...collidedNode.data },
                node: { schemaId: d.id, ...d.data },
            });

            // Check the results of any collision and do appropriate updates of warnings
            if (updateRequest?.error) {
                displayMessage(updateRequest.error); // Display any warning or error message
            } else if (updateRequest) {
                const dbResponse = await updateChart({ // Update the database
                    action: updateRequest.action,
                    openChildId: { [collidedNode.d3id]: { x0: collidedNode.x, y0: collidedNode.y, isOpen: true } },
                });

                select(`#${collidedNode.d3id} rect`) // Un-highlight the collided node
                    .attr('filter', null);

                updateDragNode(null, collidedNode); // Clear the dragged node
                console.log(`This was dropped onto ${collidedNode.d3id} - ${collidedNode.data.id}`);
            }
        }
    }

    useEffect(() => {
        if (!root) return; // Render after the root node has been initialized

        // Select the main container using the tree's id
        // const svg = select(`#${treeId}-container`); // The g that zoom is on.

        function chartUpdate() {
            if (!gNode) return; // Wait for the gNode to be initialized

            const transition = svg.transition() // Hold the alt key to slow down the transition
                .duration(altKey ? 5000 : 500);

            const nodes = root.descendants();
            const links = root.links();

            // Resize the tree based on layout and screen size
            const nodeSpaceHorizontal = Math.max(containerWidth / treeHeight(root), minSpaceHorizontal);
            const d3Tree = tree() // Define the tree layout and the shape for links.
                .nodeSize([nodeSpaceVertical, nodeSpaceHorizontal]);
            d3Tree(root); // Compute the new tree layout.

            // If there is a valid draggable node, add it to nodes to be rendered.
            console.log(`Current Drag Node: ${currentDragNode ? currentDragNode.data.id : 'None'}`);
            // Node must exist in the dictionary to be draggable
            if (currentDragNode && currentDragNode.draggable && omcDictionary.getNode(currentDragNode.data.id)) {
                nodes.push({ ...currentDragNode, ...{ nodes } });
            }

            const node = gNode.selectAll('g')
                .data(nodes, (d) => d.d3id)
                .join(
                    ((enter) => {
                        // console.log('Enter', enter);
                        const g = enter.append('g')
                            .attr('transform', (d) => {
                                const shape = nodeRender.shape(d);
                                const s = d.parent
                                    ? exitOffsetLink({
                                        x: (d.parent.x0 || d.parent.x) - shape.height / 2,
                                        y: (d.parent.y0 || d.parent.y),
                                    }, shape) // Spawn new nodes at link exit point
                                    : { y: d.y - shape.width / 2, x: d.x - shape.height / 2 };
                                return `translate(${s.y}, ${s.x})`;
                            })
                            .attr('fill-opacity', 0)
                            .attr('stroke-opacity', 0)
                            .call(drag()
                                .clickDistance(100)
                                .on('start', dragStarted)
                                .on('drag', function dragy(event, dragNode) {
                                    dragged.call(this, {
                                        event, dragNode, nodeRender, canDrop,
                                    });
                                })
                                .on('end', async function dragEnd(e, d) {
                                    if (d.draggable) await dragEnded.call(this, e, d);
                                }))
                            .on('mouseover', (e, d) => {
                                if (!e.ctrlKey && !d.draggable) updateSideBar({ id: d.data.id, d3id: d.d3id }); // If Ctrl not held and not dragging
                            })
                            .on('contextmenu', (e, d) => { // Binding this in the merge ensure draggableNode is in scope
                                e.preventDefault();
                                displayContextMenu({
                                    event: e,
                                    items: nodeRender.contextMenu(d),
                                    onClick: contextAction,
                                    node: d,
                                });
                            })
                            .attr('id', (d) => d.d3id);

                        g.append('rect')
                            .attr('width', (d) => nodeRender.shape(d).width)
                            .attr('height', (d) => nodeRender.shape(d).height)
                            .attr('ry', (d) => nodeRender.shape(d).radius)
                            .attr('rx', (d) => nodeRender.shape(d).radius)
                            .attr('id', (d) => (d.draggable ? 'dragNode' : null))
                            .attr('fill', (d) => (nodeRender.fillColor(d)));

                        g.append('rect')
                            .attr('class', 'status-rect')
                            .attr('width', (d) => nodeRender.shape(d).height / 2)
                            .attr('height', (d) => nodeRender.shape(d).height)
                            .attr('ry', (d) => nodeRender.shape(d).radius)
                            .attr('rx', (d) => nodeRender.shape(d).radius)
                            .attr('opacity', (d) => (nodeRender.statusColor(d, statusColor, omcDictionary) ? 1 : 0)) // Hide rect if no status
                            .attr('fill', (d) => nodeRender.statusColor(d, statusColor, omcDictionary)); // Set color of status rectangle

                        g.append('text')
                            .attr('x', (d) => (nodeRender.shape(d).width) / 2)
                            .attr('y', (d) => (nodeRender.shape(d).height / 2) + fontHeight / 2)
                            .attr('text-anchor', 'middle')
                            .text((d) => nodeRender.prefLabel(d, omcDictionary));

                        g.append('circle')
                            .attr('cx', (d) => (nodeRender.shape(d).width))
                            .attr('cy', (d) => (nodeRender.shape(d).height) / 2)
                            .attr('fill', (d) => nodeRender.expand(d))
                            .on('click', (e, d) => {
                                e.stopPropagation(); // Toggle the open/closed state of the node and save the position
                                updateChart({ openChildId: { [d.d3id]: { x0: d.x, y0: d.y, isOpen: !d.children } } });
                            });
                        return g;
                    }),
                    (update) => {
                        // console.log('Update:', update);
                        update.on('contextmenu', (e, d) => { // Must be bound after each update, so that contextAction has current scope
                            e.preventDefault();
                            displayContextMenu({
                                event: e,
                                items: nodeRender.contextMenu(d),
                                onClick: contextAction,
                                node: d,
                            });
                        });
                        update.select('text')
                            .text((d) => nodeRender.prefLabel(d, omcDictionary));
                        update.select('.status-rect')
                            .attr('opacity', (d) => (nodeRender.statusColor(d, statusColor, omcDictionary) ? 1 : 0)) // Hide rect if no status
                            .attr('fill', (d) => nodeRender.statusColor(d, statusColor, omcDictionary)); // Set color of status rectangle
                        update.select('circle') // Must be bound after each update, so that updateChart has current scope
                            .on('click', (e, d) => {
                                e.stopPropagation(); // Toggle the open/closed state of the node and save the position
                                updateChart({ openChildId: { [d.d3id]: { x0: d.x, y0: d.y, isOpen: !d.children } } });
                            });
                        return update;
                    },
                    ((exit) => {
                        return exit.transition(transition)
                            .remove()
                            .attr('transform', (d) => {
                                const shape = nodeRender.shape(d);
                                const dragExit = d.draggable ? (dragNodeId.location || d) : currentDragNode; // Draggable nodes stay stationary
                                const exitPoint = d.parent ? d.parent : { x: dragExit.x, y: dragExit.y - shape.width }; // Use a node parent, or the source passed in
                                const s = exitOffsetLink({
                                    x: exitPoint.x - shape.height / 2,
                                    y: exitPoint.y,
                                }, shape);
                                return `translate(${s.y}, ${s.x})`;
                            })
                            .attr('fill-opacity', 0)
                            .attr('stroke-opacity', 0)
                            .select('.status-rect')
                            .attr('stroke-opacity', 0);
                    }),
                );

            node.transition(transition)
                .attr('transform', (d) => {
                    const shape = nodeRender.shape(d);
                    return d.draggable // Draggable nodes do not transition, will remain in same position
                        ? dragNodeTransform(d, nodeRender)
                        : `translate(${d.y - shape.width / 2}, ${d.x - shape.height / 2})`;
                })
                .attr('fill-opacity', 1)
                .attr('stroke-opacity', 1);

            node.select('circle')
                .transition(transition)
                .attr('r', (d) => (d._children || d.children ? 9 : 0));

            // Update the links…
            gLink.selectAll('path')
                .data(links, (d) => `${d.source.d3id}-${d.target.d3id}`) // return d.target.id
                .join(
                    (enter) => {
                        return enter.append('path')
                            .attr('id', (d) => `${d.source.d3id}-${d.target.d3id}`)
                            .attr('d', (d) => {
                                const s = exitOffsetLink(
                                    { x: d.source.x0 || d.source.x, y: d.source.y0 || d.source.y },
                                    nodeRender.shape(d.source),
                                );
                                return diagonal({
                                    source: s, target: s,
                                });
                            }).transition(transition)
                            .attr('d', (d) => (
                                diagonal({
                                    source: exitOffsetLink(d.source, nodeRender.shape(d.source)),
                                    target: entryOffsetLink(d.target, nodeRender.shape(d.target)),
                                })
                            ));
                    },
                    (d3Update) => d3Update.transition(transition)
                        .attr('d', (d) => (
                            diagonal({
                                source: exitOffsetLink(d.source, nodeRender.shape(d.source)),
                                target: entryOffsetLink(d.target, nodeRender.shape(d.target)),
                            })
                        )),
                    (exit) => {
                        return exit.transition(transition)
                            .remove()
                            .attr('d', (d) => {
                                return diagonal({
                                    // source: exitOffsetLink(d.source, nodeRender.shape(d.source)),
                                    // target: exitOffsetLink(d.source, nodeRender.shape(d.source)),
                                    source: exitOffsetLink(d.source, nodeRender.shape(d.source)),
                                    target: linkExitOffsetLink(d.source, nodeRender.shape(d.source)),
                                });
                            });
                    },
                );
        }

        chartUpdate();
    }, [root, gNode, currentDragNode, triggerRender, containerDimensions]);

    return null;
}

OmcJsonTreeDiagram.propTypes = {
    updateSideBar: PropTypes.func, // Function to update sidebar
    updateDragNode: PropTypes.func, // Call to change the draggable node
    updateChart: PropTypes.func, // Call to update the vocabulary data
    displayMessage: PropTypes.func, // Display a message to the user such as a warning
    displayContextMenu: PropTypes.func, // Display a context menu for the user
    triggerRender: PropTypes.number, // Forces a re-render of the tree after table has updated
    treeId: PropTypes.string, // Unique Id for the tree container in DOM
    dragNodeId: PropTypes.shape({ // Detail for draggable node, and it's location for entering/exiting
        nodeId: PropTypes.string,
        location: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
    }),
    containerDimensions: PropTypes.shape({ // The dimensions of the container for the tree
        width: PropTypes.number,
        height: PropTypes.number,
    }),
    zoomPosition: PropTypes.shape({ // The current zoom position of the tree
        k: PropTypes.number,
        x: PropTypes.number,
        y: PropTypes.number,
    }),
};

export default OmcJsonTreeDiagram;
