const makeArray = (val) => (Array.isArray(val) ? val : [val]);

/**
 * Return the properties for this node
 * @param nId {string} - The identifier of thw requested node
 * @returns {{} | null} - The properties of the requested node
 */
export function getNode(nId) {
    return this.nodes[nId] || null;
}

/**
 * The identifiers of all nodes of type 'entity'
 * @param type {string | string[]} - A single, or array of omc types
 * @returns {string[]} - The identifiers for all nodes of the requested types
 */
export function getType(type) {
    const types = makeArray(type);
    return Object.keys(this.nodes)
        .filter((nId) => types.includes(this.nodes[nId].type));
}

/**
 * Return id's for any node related to this node with the relation name
 * @param nId {string} - The identifier of thw requested node
 * @param relation {string | string[]} - A single relationship name or an array of relationship names
 * @returns {string[]} - The identifiers of nodes that are related using the requested relationship names
 */
export function getRelated(nId, relation) {
    const relations = makeArray(relation);
    return this.edges[nId].filter((e) => relations.includes(e.relation))
        .map((n) => n.targetId);
}

/**
 * Make updates to the Neo4J database then internal cache
 * @param action
 */

export function cacheUpdate(action) {
    if (action.create) {
        action.create.forEach((c) => {
            if (c.sourceId) {
                const { sourceId } = c;
                this.edges[sourceId] = this.edges[sourceId] ? [...this.edges[sourceId], c] : [c];
            } else {
                const { id } = c;
                this.nodes[id] = c;
                if (!this.edges[id]) this.edges[id] = []; // Ensure there is an entry for the edges
            }
        });
    }
    if (action.update) {
        action.update.forEach((c) => {
            if (c.sourceId) {
                const { sourceId } = c;
                this.edges[sourceId] = this.edges[sourceId] ? [...this.edges[sourceId], c] : [c];
            } else {
                const { id } = c;
                Object.keys(c)
                    .forEach((k) => {
                        this.nodes[id][k] = c[k];
                    });
            }
        });
    }
    if (action.delete) {
        action.delete.forEach((d) => {
            if (d.sourceId) {
                const { sourceId } = d;
                const keepEdges = this.edges[sourceId].filter((e) => {
                    return !(e.targetId === d.targetId && e.relation === d.relation);
                });
                this.edges[sourceId] = keepEdges;
            } else {
                const { id } = d;
                if (this.nodes[id]) delete this.nodes[id];
                if (this.edges[id]) delete this.edges[id];
                Object.keys(this.edges)
                    .forEach((sourceId) => {
                        const keepEdges = this.edges[sourceId].filter((e) => e.targetId !== id);
                        this.edges[sourceId] = keepEdges;
                    });
            }
        });
    }
}

// Create an error response for the front end
export const errorResponse = (description) => ({
    error: {
        title: 'OMC Error',
        description,
        status: 'error',
    }
});

// Create an error response for the front end
export const warningResponse = (description) => ({
    warning: {
        title: 'OMC Warning',
        description,
        status: 'warning',
    }
});

/**
 * Creates a node object for the database action
 * @type {function(*): {id, label, type, status: null}|null}
 */
export const createNode = ((row) => {
    return row ? {
        id: row.id,
        label: row.label,
        type: row.type,
        status: row.status || null,
    } : null;
});

/**
 * Creates a node object for the database action
 * @param source
 * @param target
 * @param relation
 * @returns {{sourceId, targetId, sourceType, targetType, relation}}
 */

export function createEdge(source, target, relation) {
    const sourceNode = typeof source === 'string' ? this.getNode(source) : source;
    const targetNode = typeof target === 'string' ? this.getNode(target) : target;
    return {
        sourceId: sourceNode.id,
        sourceType: sourceNode.type,
        targetId: targetNode.id,
        targetType: targetNode.type,
        relation,
    };
}